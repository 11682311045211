import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import {OAuthService} from "angular-oauth2-oidc";
import {TenantService} from "../shared/services/multitenant/multitenant.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private oauthService: OAuthService, private tenantService: TenantService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //console.log(`Enviando solicitud: ${request.url}`, request);
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          console.log(err)
          if (err.status === 401 || err.status === 403) {
            this.oauthService.logOut({ client_id : this.tenantService.clientEnvironment.environment.auth.clientId});
            //this.router.navigate(["/login"]);
          }

          // return the error back to the caller
          return throwError(err);
        }
      })
    );
  }
}
