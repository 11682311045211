export const environment = {
  production: true,
  register: true,
  pitchSection: false,
  positionReport: true,
  callToAction: false,
  protectionSection: false,
  actionExample: false,
  shouldShowProductDetail: false,
  showAdvancedProfile: false,
  showLogo: true,
  clientLogo: false,
  cssPath: './assets/client/abanca/css/client.css',
  api: {
    baseUrl: "https://abanca.api.newinvest.io/api/v1/",
    countries: "regions",
    businesses: "businesses",
    interlocutors: "interlocutors",
    risks: "risks",
  },
  availablePageSizes: [5, 10, 20, 50, 100],
  auth: {
    issuer:
      "https://valora-idp.azurewebsites.net/realms/newinvest-abanca",
    clientId: "esg-web-pro",
    tokenEndpoint:
      "https://valora-idp.azurewebsites.net/realms/newinvest-abanca/protocol/openid-connect/token",
    userInfoEndpoint:
      "https://valora-idp.azurewebsites.net/realms/newinvest-abanca/protocol/openid-connect/userinfo",
    requireHttps: false,
    allowedUrls: [
      "https://abanca.api.newinvest.io/",
      "http://abanca.api.newinvest.io/",
      "https://localhost:7036/",
    ],
  },
};
