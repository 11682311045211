export const environment = {
  language: 'es-ES',
  type: 'local',
  production: true,
  cssPath: './assets/client/santander/css/client.css',
  api: {
    baseUrl: "https://santander.api.newinvest.io/api/v1/",
    countries: "regions",
    businesses: "businesses",
    interlocutors: "interlocutors",
    risks: "risks",
  },
  availablePageSizes: [5, 10, 20, 50, 100],
  auth: {
    issuer:
      "https://valora-idp.azurewebsites.net/realms/valora-santander-dev",
    clientId: "esg-web-dev",
    tokenEndpoint:
      "https://valora-idp.azurewebsites.net/realms/valora-santander-dev/protocol/openid-connect/token",
    userInfoEndpoint:
      "https://valora-idp.azurewebsites.net/realms/valora-santander-dev/protocol/openid-connect/userinfo",
    requireHttps: false,
    allowedUrls: [
      "https://santander.api.newinvest.io/",
      "http://santander.api.newinvest.io/",
      "https://localhost:7036/",
    ],
  },
};
