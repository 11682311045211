export const environment = {
  type: 'dev',
  language: 'es-ES',
  production: true,
  register: true,
  pitchSection: false,
  positionReport: true,
  callToAction: true,
  protectionSection: false,
  actionExample: false,
  shouldShowProductDetail: true,
  showAdvancedProfile: false,
  showLogo: true,
  clientLogo: false,
  noLawModal: true,
  restrictedReg: true,
  masterCIF: './assets/client/abanca/masterCIF.json',
  cssPath: './assets/client/abanca/css/client.css',
  api: {
    baseUrl: "https://abanca.api.dev.newinvest.io/api/v1/",
    countries: "regions",
    businesses: "businesses",
    interlocutors: "interlocutors",
    risks: "risks",
  },
  availablePageSizes: [5, 10, 20, 50, 100],
  auth: {
    issuer:
      "https://valora-idp.azurewebsites.net/realms/ESG-abanca-dev",
    clientId: "esg-web-dev",
    tokenEndpoint:
      "https://valora-idp.azurewebsites.net/realms/ESG-abanca-dev/protocol/openid-connect/token",
    userInfoEndpoint:
      "https://valora-idp.azurewebsites.net/realms/ESG-abanca-dev/protocol/openid-connect/userinfo",
    requireHttps: false,
    allowedUrls: [
      "https://abanca.api.dev.newinvest.io/",
      "http://abanca.api.dev.newinvest.io/",
      "https://localhost:7036/",
    ],
  },
};
