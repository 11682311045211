import { CompanyFacility } from "./company-facility";
import { CompanyLogo } from "./company-logo";
import { Entity } from "./entity";

interface Size {
  id: string;
  name: string;
}

interface Location {
  id: string;
  name: string;
}

interface SubSector {
  id: string;
  name: string;
}


export class Company extends Entity {
  public name: string;
  public size: Size;
  public locations: Location[];
  public subSectors: SubSector[];
  public logo: CompanyLogo | null;
  public objectiveIndicatorCount: number;
  public objectiveIndicatorNamePlural: string;
  public contactName: string | null;
  public contactAddress: string | null;
  public contactEmail: string | null;
  public contactPhone: string | null;
  public facilities: CompanyFacility[];
  public hasAcceptedTermsOfUse: boolean;

  constructor(
    id: string,
    size: Size,
    locations: Location[],
    subSectors: SubSector[],
    logo: CompanyLogo | null,
    objectiveIndicatorCount: number,
    objectiveIndicatorNamePlural: string,
    contactName: string | null,
    contactAddress: string | null,
    contactEmail: string | null,
    contactPhone: string | null,
    facilities: CompanyFacility[],
    hasAcceptedTermsOfUse: boolean
  ) {
    super(id);
    this.size = size;
    this.locations = locations;
    this.subSectors = subSectors;
    this.logo = logo;
    this.objectiveIndicatorCount = objectiveIndicatorCount;
    this.objectiveIndicatorNamePlural = objectiveIndicatorNamePlural;
    this.contactName = contactName;
    this.contactAddress = contactAddress;
    this.contactEmail = contactEmail;
    this.contactPhone = contactPhone;
    this.facilities = facilities;
    this.hasAcceptedTermsOfUse = hasAcceptedTermsOfUse
  }

  // public static transformSizeText(size: Size): string {
  //   switch(size.name.toLowerCase()){
  //     case "small":
  //       return "Menos de 1M€";
  //     case "medium":
  //       return "Entre 1M€ e 50M€";
  //     case "large":
  //       return "Más de 50M€";
  //     default:
  //       return "Sin definir";
  //   }
  // }
}
