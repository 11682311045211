import { ActionOportunity } from "./action-oportunity";
import { ActionProduct } from "./action-product";
import { ActionSuggestedAction } from "./action-suggested-action";
import { Entity } from "./entity";

export class Action extends Entity {
  public companyId: string;
  public opportunities: Array<ActionOportunity>;
  public products: Array<ActionProduct>;
  public suggestedActions: Array<ActionSuggestedAction>;

  constructor(
    id: string,
    companyId: string,
    opportunities: Array<ActionOportunity>,
    products: Array<ActionProduct>,
    suggestedActions: Array<ActionSuggestedAction>
  ) {
    super(id);
    this.companyId = companyId;
    this.opportunities = opportunities;
    this.products = products;
    this.suggestedActions = suggestedActions;
  }
}
