import { Entity } from "./entity";

export class CompanyFacility extends Entity {
  public name: string;

  constructor(id: string, name: string) {
    super(id);
    this.name = name;
  }
}
