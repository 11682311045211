import { ActionSuggestedActionGoal } from "./action-suggested-action-goal";
import { ActionSuggestedActionSolutionType } from "./action-suggested-action-solution-type";
import { Entity } from "./entity";

export class ActionSuggestedAction extends Entity {
  public actionGoals: Array<ActionSuggestedActionGoal>;
  public isSelected: boolean;
  public name: string;
  public sampleInvestment: string;
  public sampleProfit: string;
  public sampleROI: string;
  public sampleText: string;
  public samplesHeader: string;
  public title: string;
  public solutionType: ActionSuggestedActionSolutionType;
  constructor(
    id: string,
    actionGoals: Array<ActionSuggestedActionGoal>,
    isSelected: boolean,
    name: string,
    sampleInvestment: string,
    sampleProfit: string,
    sampleROI: string,
    sampleText: string,
    samplesHeader: string,
    title: string,
    solutionType: ActionSuggestedActionSolutionType
  ) {
    super(id);
    this.actionGoals = actionGoals;
    this.isSelected = isSelected;
    this.name = name;
    this.sampleInvestment = sampleInvestment;
    this.sampleProfit = sampleProfit;
    this.sampleROI = sampleROI;
    this.sampleText = sampleText;
    this.title = title;
    this.solutionType = solutionType;
  }
}
