export const environment = {
  language: 'es-ES',
  type: 'local',
  production: false,
  api: {
    baseUrl: "https://santander.api.newinvest.dev/api/v1/",
  },
  availablePageSizes: [5, 10, 20, 50, 100],
   auth: {
     issuer:
       "https://valora-idp.azurewebsites.net/realms/valora-santander-dev",
     clientId: "esg-web-dev",
     tokenEndpoint:
       "https://valora-idp.azurewebsites.net/realms/valora-santander-dev/protocol/openid-connect/token",
     userInfoEndpoint:
       "https://valora-idp.azurewebsites.net/realms/valora-santander-dev/protocol/openid-connect/userinfo",
     requireHttps: false,
     allowedUrls: [
       "https://santander.api.newinvest.dev/",
       "http://santander.api.newinvest.dev/",
       "https://localhost:7008/",
     ],
   },
};

