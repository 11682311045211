import { createReducer, on } from '@ngrx/store';
import { addCompanyFacility, removeCompanyFacility, saveCompanyData, saveCompanyLogo } from './../actions/company.actions';
import { Company } from "../shared/domain";

export interface CompanyState {
  company: Company | null;
}

export const initialState: CompanyState = {
  company: null,
};

export const companyReducer = createReducer(
  initialState,
  on(saveCompanyData, (state, { company }) => ({ ...state, company })),
  on(saveCompanyLogo, (state, { logo }) => {
    let company = state.company;
    company = { ...company, logo: logo };
    return { ...state, company }
  }),
  on(addCompanyFacility, (state, { facility }) => {
    if(state.company.facilities.some((el) => el.id === facility.id)){
      const val = state.company.facilities.find((el) => el.id === facility.id)
      val.name = facility.name
    }else {
      state.company.facilities.push(facility)
    }

    return state;
  }),
  on(removeCompanyFacility, (state, { facility }) => {
    state.company.facilities = state.company.facilities.filter((el) => el.id != facility.id);
    return state;
  })
);
