import { inject, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable } from "rxjs";
import { AuthService } from "../services/idp/auth/auth.service";
import { CompanyService } from "../services/company/company.service";
import { Store } from '@ngrx/store';
import { saveCompanyData } from '../../actions/company.actions';
import { CompanyState } from '../../reducer/company.reducer';


@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor( private authService: AuthService, private companyService: CompanyService, private store: Store<{ company: CompanyState }>, private router: Router) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
     
    if (!this.authService.hasValidAccessToken()) {
      this.router.navigateByUrl("/login");
      return false;
    }else{
      this.companyService.getCompany().subscribe((data) => {
        this.store.dispatch(saveCompanyData({ company: data }));
      });
      return true;
    }
    
  }
}
