export const environment = {
  language: 'es-ES',
  type: 'dev',
  production: true,
  register: true,
  pitchSection: true,
  positionReport: false,
  callToAction: true,
  protectionSection: false,
  actionExample: false,
  shouldShowProductDetail: true,
  showAdvancedProfile: true,
  showLogo: true,
  clientLogo: false,
  noLawModal: true,
  cssPath: './assets/client/gcc/css/client.css',
  api: {
    baseUrl: "https://cajamar.api.dev.newinvest.io/api/v1/",
    countries: "regions",
    businesses: "businesses",
    interlocutors: "interlocutors",
    risks: "risks",
  },
  availablePageSizes: [5, 10, 20, 50, 100],
  auth: {
    issuer:
      "https://valora-idp.azurewebsites.net/realms/esg-cajamar-dev",
    clientId: "esg-web-dev",
    tokenEndpoint:
      "https://valora-idp.azurewebsites.net/realms/esg-cajamar-dev/protocol/openid-connect/token",
    userInfoEndpoint:
      "https://valora-idp.azurewebsites.net/realms/esg-cajamar-dev/protocol/openid-connect/userinfo",
    requireHttps: false,
    allowedUrls: [
      "https://cajamar.api.dev.newinvest.io/",
      "http://cajamar.api.dev.newinvest.io/",
      "https://localhost:7036/",
    ],
  },
};
