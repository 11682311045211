import { Entity } from "./entity";
import { PositionRecomendation } from "./position-recomendation";
import { PositionType } from "./position-type";

export class Position {
  private static readonly _positionTypes: Map<number, string> = new Map([
    [1, 'BÁSICO'],
    [2, 'MEDIO'],
    [3, 'AVANZADO']
  ]);
  //public companyId: string;
  public positionType: PositionType;
  public positionCoordinates: {
    x: number;
    y: number;
  };
  public subSectorCoordinates: {
    x: number;
    y: number;
  };
  public regulatoryRisk: {
    name: string,
    disclaimer: string,
    score: number,
  };
  public transitionRisk: {
    name: string,
    disclaimer: string,
    score: number,
  };
  public sustainableClientsRisk: {
    name: string,
    disclaimer: string,
    score: number,
  };
  public savingsLoss: {
    name: string,
    disclaimer: string,
    score: number,
  };
  //public opportunityLoss: number;
  public recommendations: Array<PositionRecomendation>;

  constructor(positionType: PositionType,
              positionCoordinates: { x: number, y:number},
              subSectorCoordinates: { x: number, y:number},
              regulatoryRisk: {
                name: string,
                disclaimer: string,
                score: number,
              },
              transitionRisk: {
                name: string,
                disclaimer: string,
                score: number,
              },
              sustainableClientsRisk: {
                name: string,
                disclaimer: string,
                score: number,
              },
              savingsLoss: {
                name: string,
                disclaimer: string,
                score: number,
              },
              recommendations: Array<PositionRecomendation>) {
    //this.companyId = companyId;
    this.positionType = positionType;
    this.positionCoordinates = positionCoordinates;
    this.subSectorCoordinates = subSectorCoordinates;
    this.regulatoryRisk = regulatoryRisk
    this.transitionRisk = transitionRisk
    this.sustainableClientsRisk = sustainableClientsRisk
    this.savingsLoss = savingsLoss
    //this.opportunityLoss = opportunityLoss
    this.recommendations = recommendations
  }

  public getPositionDisplayName(): string {
    return Position._positionTypes.get(this.positionType.id);
  }

  public getNextPositionDisplayName(): string {
    let value = this.positionType.id + 1;
    if(value > 3) {
      value = 3;
    }
    return Position._positionTypes.get(value);
  }

}
