import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { filter } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { UserProfile } from 'src/app/shared/domain/user-profile';
import { TenantService } from '../../multitenant/multitenant.service';

export interface IProfileInfo {
  info: UserProfile;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly router: Router,
    private readonly oauthService: OAuthService,
    private readonly tenantService: TenantService
  ) {
    console.log(tenantService.clientEnvironment);
    this.configureAuth();
    this.oauthService.events
      .pipe(filter((e) => e.type === 'token_received'))
      .subscribe((data) => {
        this.router.navigate(['/redirect']);
      });
    // TODO - remove not needed , Guard manage this part
    /*
    if (!this.oauthService.hasValidAccessToken()) {
      console.log('redirect to login');
      //   this.router.navigate(['login']);
    }
  */
  }

  public async login(user: string, password: string): Promise<TokenResponse> {
    return this.oauthService.fetchTokenUsingPasswordFlow(
      user,
      password,
      new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    );
  }

  public logout(): void {
    return this.oauthService.logOut({ client_id : this.tenantService.clientEnvironment.environment.auth.clientId});
  }

  public loadUserProfile(): Promise<IProfileInfo> {
    return this.oauthService.loadUserProfile() as Promise<IProfileInfo>;
  }

  public hasValidAccessToken(): Boolean {
    return this.oauthService.hasValidAccessToken()
  }

  public refreshToken() {
    return this.oauthService.refreshToken();
  }

  private configureAuth() {
    this.oauthService.configure(this.getAuthConfig());
    this.oauthService.setStorage(localStorage);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    this.oauthService.setupAutomaticSilentRefresh();
  }

  private getAuthConfig(): AuthConfig {
    const authCodeFlowConfig: AuthConfig = {
      issuer: this.tenantService.clientEnvironment.environment.auth.issuer,
      redirectUri: window.location.origin + '/',
      clientId: this.tenantService.clientEnvironment.environment.auth.clientId,
      responseType: 'code',
      scope: 'openid profile offline_access',
      showDebugInformation: isDevMode(),
      loginUrl: window.location.origin + '/signin',
      dummyClientSecret: 'abc',
      tokenEndpoint: this.tenantService.clientEnvironment.environment.auth.tokenEndpoint,
      silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html',
      silentRefreshShowIFrame: false,
      userinfoEndpoint: this.tenantService.clientEnvironment.environment.auth.userInfoEndpoint,
      useSilentRefresh: true,
      requireHttps: this.tenantService.clientEnvironment.environment.auth.requireHttps,
      oidc: false,
    };
    return authCodeFlowConfig;
  }
}
