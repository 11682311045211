import { createAction, props } from "@ngrx/store";
import { Company, CompanyFacility, CompanyLogo } from "../shared/domain";

export const saveCompanyData = createAction(
  "[Company] Save Data",
  props<{ company: Company }>()
);

export const saveCompanyLogo = createAction(
  "[Company] Save logo",
  props<{ logo: CompanyLogo }>()
);


export const addCompanyFacility = createAction(
  "[Company] add company facility",
  props<{ facility: CompanyFacility }>()
);

export const removeCompanyFacility = createAction(
  "[Company] remove company facility",
  props<{ facility: CompanyFacility }>()
);
