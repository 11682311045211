import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastService } from './shared/services/toast/toast.service';
import { TenantService } from './shared/services/multitenant/multitenant.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [MessageService, ToastService],
})
export class AppComponent implements OnInit {
  title = 'due-diligence-web';
  lang: string;

  constructor(
    private readonly tenantService: TenantService,
    private messageService: MessageService,
    private translate: TranslateService
  ) {
    this.lang = this.tenantService.clientEnvironment.environment.language;
    translate.setDefaultLang(this.lang);
  }

  ngOnInit(): void {
    const clientCssPath =this.tenantService.clientEnvironment.environment.cssPath;
    console.log(clientCssPath);

    // Cargamos dinámicamente el archivo CSS
    this.loadStyles(clientCssPath);
  }

  private loadStyles(cssPath: string): void {
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('link');
    style.rel = 'stylesheet';
    style.type = 'text/css';
    style.href = cssPath;
    head.appendChild(style);
  }

  cambiarIdioma(idioma: string) {
    this.translate.use(idioma);
  }
}
