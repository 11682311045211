import { Entity } from "./entity";

export class QuestionAnswer extends Entity {
  public isSelected: boolean;
  public order: number;
  public text: string;
  public type: string;
  public value: string | number | null;
  public unit: string;
  public facilities: Array<{
    facilityId: string;
    value: string | number;
  }>

  constructor(id: string, isSelected: boolean, order: number, text:string, type: string, value: string | number | null, unit: string, facilities: Array<{
    facilityId: string;
    value: string | number;
  }>) {
    super(id);
    this.isSelected = isSelected;
    this.value = value;
    this.order = order;
    this.text = text;
    this.type = type
    this.unit = unit;
    this.facilities = facilities;
  }
}