export const environment = {
  language: 'en-US',
  type: 'stage',
  production: true,
  register: true,
  pitchSection: true,
  positionReport: false,
  callToAction: true,
  protectionSection: false,
  actionExample: false,
  shouldShowProductDetail: false,
  showAdvancedProfile: true,
  showLogo: true,
  clientLogo: false,
  noLawModal: true,
  cssPath: './assets/client/banorte/css/client.css',
  api: {
    baseUrl: "https://banorte.api.newinvest.io/api/v1/",
    countries: "regions",
    businesses: "businesses",
    interlocutors: "interlocutors",
    risks: "risks",
  },
  availablePageSizes: [5, 10, 20, 50, 100],
  auth: {
    issuer:
      "https://valora-idp.azurewebsites.net/realms/newinvest-banorte",
    clientId: "esg-web-pro",
    tokenEndpoint:
      "https://valora-idp.azurewebsites.net/realms/newinvest-banorte/protocol/openid-connect/token",
    userInfoEndpoint:
      "https://valora-idp.azurewebsites.net/realms/newinvest-banorte/protocol/openid-connect/userinfo",
    requireHttps: false,
    allowedUrls: [
      "https://banorte.api.newinvest.io/",
      "http://banorte.api.newinvest.io/",
      "https://localhost:7036/",
    ],
  },
};
