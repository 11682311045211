import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export class MultiTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient, private resources: { prefix: string, suffix: string }[]) {}

  public getTranslation(lang: string): Observable<any> {
    return forkJoin(
      this.resources.map(config => this.http.get(`${config.prefix}${lang}${config.suffix}`))
    ).pipe(
      map(response => {
        return response.reduce((a, b) => ({ ...a, ...b }), {});
      })
    );
  }
}
