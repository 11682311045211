import { createReducer, on } from "@ngrx/store";
import { Journey } from "../shared/domain/journey";
import { saveJourneyData } from "../actions/journey.action";

export interface JourneyState {
  journey: Journey | null;
}

export const initialState: JourneyState = {
  journey: null,
};

export const journeyReducer = createReducer(
  initialState,
  on(saveJourneyData, (state, { journey }) => {
    return { ...state, journey };
  })
);
