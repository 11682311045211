import { Entity } from "./entity";

export class ActionOportunity extends Entity {
  public name: string;
  public value: number;

  constructor(id: string, name: string, value: number) {
    super(id);
    this.name = name;
    this.value = value;
  }
}
