import { Entity } from "./entity";
import { QuestionAnswer } from "./question-answer";

export class QuestionGroup extends Entity {
  public name: string;
  public order: number;
  public answers: Array<QuestionAnswer>;
  constructor(
    id: string,
    name: string,
    order: number,
    answers: Array<QuestionAnswer>
  ) {
    super(id);
    this.name = name;
    this.order = order;
    this.answers = answers;
  }
}
