export class CompanyCommand {
  companyName: string;
  subSectorId: string;
  objectiveIndicatorCount: number;
  companySizeId: string;
  locations: string[];
  contactName: string;
  contactAddress: string;
  contactEmail: string;
  contactPhone: string;
}
