import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./shared/guards/auth-guard";

const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginModule),
    pathMatch: "full",
  },
  {
    path: "recover-password",
    loadChildren: () =>
      import("./pages/recover-password/recover-password.module").then(
        (m) => m.RecoverPasswordModule
      ),
    pathMatch: "full",
  },
  {
    path: "reset-password-update",
    loadChildren: () =>
      import("./pages/recover-password-token/recover-password-token.module").then(
        (m) => m.RecoverPasswordTokenModule
        ),
    pathMatch: "full",
  },
  {
    path: "register",
    loadChildren: () =>
      import("./pages/register/register.module").then(
        (m) => m.RegisterModule
      ),
    pathMatch: "full",
  },
  {
    path: "",
    loadChildren: () =>
      import("./pages/sections/sections.module").then((m) => m.SectionsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "sign-in",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginModule),
    pathMatch: "full",
  },
  {
    path: "**",
    loadChildren: () =>
      import("./pages/not-found/not-found.module").then(
        (m) => m.NotFoundModule
      ),
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
