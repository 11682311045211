export const environment = {
  language: 'es-ES',
  production: true,
  api: {
    baseUrl: "https://abanca.api.wappy.cloud/api/v1/",
    countries: "regions",
    businesses: "businesses",
    interlocutors: "interlocutors",
    risks: "risks",
  },
  availablePageSizes: [5, 10, 20, 50, 100],
  auth: {
    issuer:
      "https://valora-idp.azurewebsites.net/realms/ESG-abanca-dev",
    clientId: "esg-web-dev",
    tokenEndpoint:
      "https://valora-idp.azurewebsites.net/realms/ESG-abanca-dev/protocol/openid-connect/token",
    userInfoEndpoint:
      "https://valora-idp.azurewebsites.net/realms/ESG-abanca-dev/protocol/openid-connect/userinfo",
    requireHttps: false,
    allowedUrls: [
      "https://abanca.api.wappy.cloud/",
      "http://abanca.api.wappy.cloud/",
      "https://localhost:7036/",
    ],
  },
};
