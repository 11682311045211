<div class="product-detail-container" *ngIf="product">
  <svg class="close" (click)="close()" width="40" height="40" viewBox="0 0 40 40" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 18.1485L25.556 12.5925C26.0673 12.0812 26.8963 12.0812 27.4076 12.5925C27.9189 13.1038 27.9189 13.9327 27.4076 14.444L21.8516 20L27.4076 25.556C27.9189 26.0673 27.9189 26.8963 27.4076 27.4076C26.8963 27.9189 26.0673 27.9189 25.556 27.4076L20 21.8516L14.444 27.4076C13.9327 27.9189 13.1037 27.9189 12.5925 27.4076C12.0812 26.8963 12.0812 26.0673 12.5925 25.556L18.1484 20L12.5925 14.444C12.0812 13.9327 12.0812 13.1038 12.5925 12.5925C13.1037 12.0812 13.9327 12.0812 14.444 12.5925L20 18.1485Z"
      fill="var(--mainColor)"/>
    <rect x="0.833333" y="0.833333" width="38.3333" height="38.3333" rx="19.1667" stroke="var(--mainColor)"
          stroke-width="1.66667"/>
  </svg>
  <p class="product-name">{{ product.name }}</p>
  <div class="product-description" [innerHTML]="product.description"></div>
  <div class="product-detail-items-container">
    <div class="product-detail-item"
         [class.even]="even"
         [class.odd]="odd"
         *ngFor="let detail of product.details; let even = even; let odd = odd">
      <div class="product-detail-item-content">
      <div class="bar"></div>
      <h4>{{ detail.name }}</h4>
      <div class="product-detail-raw" [innerHTML]="detail.rawDescription">
      </div>
      </div>
    </div>
  </div>
  <p class="contact">{{ 'productModal.info' | translate}}</p>
  <p class="small">
    {{ 'productModal.footer' | translate}}
  </p>
</div>
