import { Entity } from "./entity";

export class ActionSuggestedActionGoal extends Entity {
  public name: string;
  public imageUrl: string;

  constructor(id: string, name: string, imageUrl: string) {
    super(id);
    this.name = name;
    this.imageUrl = imageUrl;
  }
}
