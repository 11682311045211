import {Component, Input, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ActionProduct} from "../../shared/domain";
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from '../../shared/services/multitenant/multitenant.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {

  public product: ActionProduct = new ActionProduct("","","","",false);
  lang: string;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private translate: TranslateService,
    private readonly tenantService: TenantService
  ) {
    this.lang = this.tenantService.clientEnvironment.environment.language;
    translate.setDefaultLang(this.lang);
  }

  ngOnInit(): void {
    this.product = this.config.data;
  }


  public close(): void{
    this.ref.close();
  }
}
