import { Entity } from "./entity";
import {ProductDetail} from "./product-detail";

export class ActionProduct extends Entity {
  public name: string;
  public targetURL: string;
  public isHighlighted: boolean;
  public description: string;
  public details: Array<ProductDetail>;

  constructor(id: string, name: string, targetURL: string, description: string, isHighlighted: boolean = false) {
    super(id);
    this.name = name;
    this.targetURL = targetURL;
    this.description = description;
    this.isHighlighted = isHighlighted;
    this.details = new Array<ProductDetail>();
  }
}
