import { Entity } from "./entity";

export class PositionRecomendation extends Entity {
  public text: string;

  constructor(id: string, text: string) {
    super(id);
    this.text = text;
  }
}
