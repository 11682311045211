import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { OAuthModule, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'primeng/toast';
import { companyReducer } from "src/app/reducer/company.reducer";
import { StoreModule } from '@ngrx/store';
import { journeyReducer } from './reducer/journey.reducer';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { TenantService } from './shared/services/multitenant/multitenant.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MultiTranslateLoader } from './shared/modules/translation/multi-translate-loader';

export function setClientEnvironment(tenantService: TenantService): () => Promise<void> {
  return () => tenantService.setClientEnvironment();
}

export function oauthModuleConfigFactory(tenantService: TenantService): any {
  const allowedUrls = tenantService.clientEnvironment
    ? tenantService.clientEnvironment.environment.auth.allowedUrls
    : [];

  return {
    resourceServer: {
      sendAccessToken: true,
      allowedUrls: allowedUrls,
    },
  };
}

export function HttpLoaderFactory(http: HttpClient, tenantService: TenantService) {
  // Obtenemos el cliente del tenantService
  const client = tenantService.clientEnvironment?.client || '';

  return new MultiTranslateLoader(http, [
    { prefix: './assets/i18n/', suffix: '.json' },
    { prefix: `./assets/i18n/${client}/`, suffix: '.json' }
  ]);
}

@NgModule({
  declarations: [AppComponent, ProductDetailComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    OAuthModule.forRoot(),
    ToastModule,
    StoreModule.forRoot({ company: companyReducer, journey: journeyReducer }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, TenantService]
      }
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: setClientEnvironment,
      deps: [TenantService],
      multi: true,
    },
    TenantService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: OAuthModuleConfig,
      useFactory: oauthModuleConfigFactory,
      deps: [TenantService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
