import { Entity } from "./entity";

export class CompanyLogo extends Entity {
  public name: string;
  public relativePath: string;
  public absolutePath: string;

  constructor(id: string, name: string, relativePath: string, absolutePath:string) {
    super(id);
    this.name = name;
    this.relativePath = relativePath
    this.absolutePath = absolutePath
  }
}
