import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { Position } from "../../domain/position";
import {
  Company,
  CompanyCommand,
  CompanyFacility,
  CompanyLogo,
  PositionRecomendation,
  PositionType,
} from "../../domain";
import { CompanyFacilityCommand } from "../../domain/company-facility-command";
import { Journey } from "../../domain/journey";
import { TenantService } from '../multitenant/multitenant.service';
import { NewCompany } from "../../domain/company-register";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  url: string;

  constructor(
    private httpClient: HttpClient,
    private readonly tenantService: TenantService
    ) {
      this.url = this.tenantService.clientEnvironment.environment.api.baseUrl;
    }

  getCompany(): Observable<Company> {
    return this.httpClient.get<Company>(`${this.url}companies/me`);
  }

  acceptTermsOfUse(): Observable<Company> {
    return this.httpClient.post<Company>(
      `${this.url}companies/me/terms-of-use`,
      {}
    );
  }

  updatePassword(newpassword: string): Observable<Company> {
    return this.httpClient.post<Company>(`${this.url}companies/me/password`, {
      password: newpassword,
    });
  }

  updateCompany(payload: CompanyCommand): Observable<Company> {
    return this.httpClient.put<Company>(`${this.url}companies/me`, payload);
  }

  
  addFacility(payload: CompanyFacilityCommand): Observable<CompanyFacility> {
    return this.httpClient.post<CompanyFacility>(`${this.url}companies/me/facilities`, payload);
  }

  updateFacility(payload: CompanyFacilityCommand, id: string): Observable<CompanyFacility> {
    return this.httpClient.put<CompanyFacility>(`${this.url}companies/me/facilities/${id}`, payload);
  }

  deleteFacility(id: string): Observable<CompanyFacility> {
    return this.httpClient.delete<CompanyFacility>(`${this.url}companies/me/facilities/${id}`);
  }

  journey(): Observable<Journey> {
    return this.httpClient.get<Journey>(`${this.url}companies/me/journey`);
  }

  uploadLogo(file: File): Observable<CompanyLogo> {
    const formData = new FormData();
    formData.append("file", file);

    const headers = new HttpHeaders({}); // set empty headers, autodetect browser

    return this.httpClient.post<CompanyLogo>(
      `${this.url}companies/me/logo`,
      formData,
      { headers }
    );
  }
  
  addCompany(company: NewCompany): Observable<any> {
    return this.httpClient.post<Company>(`${this.url}companies`, company);
  }
}
