export const environment = {
  production: true,
  api: {
    baseUrl: "https://esg-api-dev.azurewebsites.net/api/v1/",
    countries: "regions",
    businesses: "businesses",
    interlocutors: "interlocutors",
    risks: "risks",
  },
  availablePageSizes: [5, 10, 20, 50, 100],
  auth: {
    issuer:
      "https://valora-idp.azurewebsites.net/realms/valora-abanca-dev",
    clientId: "esg-web-dev",
    tokenEndpoint:
      "https://valora-idp.azurewebsites.net/realms/valora-abanca-dev/protocol/openid-connect/token",
    userInfoEndpoint:
      "https://valora-idp.azurewebsites.net/realms/valora-abanca-dev/protocol/openid-connect/userinfo",
    requireHttps: false,
    allowedUrls: [
      "https://esg-api-dev.azurewebsites.net/",
      "http://esg-api-dev.azurewebsites.net/",
      "https://localhost:7036/",
    ],
  },
};
