import { Entity } from "./entity";
import { QuestionAnswer } from "./question-answer";
import { QuestionFile } from "./question-file";
import { QuestionGroup } from "./question-group";
import { QuestionType } from "./question-type";

export class Question extends Entity {
  public answers: Array<QuestionAnswer>;
  public groups: Array<QuestionGroup>;
  public order: number;
  public text: string;
  public type: QuestionType;
  public files: Array<QuestionFile>

  constructor(id: string, answers: Array<QuestionAnswer>, groups: Array<QuestionGroup>, order: number, text: string, type: QuestionType, files: Array<QuestionFile>) {
    super(id);
    this.answers = answers;
    this.groups = groups;
    this.order = order;
    this.text = text;
    this.type = type;
    this.files = files
  }
}